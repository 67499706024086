import Vue from "vue";

Vue.filter("commaSeparator", (value: number | string, lang: string) => {
  const fractionSeparator = typeof value === "string" && lang === "de" ? "," : ".";
  const [integerPart, fractionalPart] = value.toString().split(fractionSeparator);
  const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, lang === "de" ? "." : ",");
  const formattedFractionSeparator = lang === "de" ? "," : ".";

  return fractionalPart
    ? `${formattedIntegerPart}${formattedFractionSeparator}${fractionalPart}`
    : formattedIntegerPart;
});

Vue.filter("fractionSeparator", (value: any, lang: string) => {
  return value.toString().replace(".", lang === "de" ? "," : ".");
});
