export enum AuditingResource {
  COMPANY_CODE = 'COMPANY_CODE',
  COMPANY_CODE_IMPORT_FILE = 'COMPANY_CODE_IMPORT_FILE',
  CO2_TYPE = 'CO2_TYPE',
  CO2_TYPE_IMPORT_FILE = 'CO2_TYPE_IMPORT_FILE',
  SUPPLIER = 'SUPPLIER',
  SUPPLIER_IMPORT_FILE = 'SUPPLIER_IMPORT_FILE',
  TRANSPORT_ROUTE = 'TRANSPORT_ROUTE',
  FINANCIAL_MODEL = 'FINANCIAL_MODEL',
  CO2_BOOKING = 'CO2_BOOKING',
  CO2_BOOKING_IMPORT_FILE = 'CO2_BOOKING_IMPORT_FILE',
  FINANCIAL_BOOKING = 'FINANCIAL_BOOKING',
  FINANCIAL_BOOKING_IMPORT_FILE = 'FINANCIAL_BOOKING_IMPORT_FILE',
  COMMUTER_SURVEY = 'COMMUTER_SURVEY',
  FINALISED_REPORT = 'FINALISED_REPORT',
}

export enum AuditingService {
  SCO2PES = 'SCO2PES',
}

export interface AuditingParams {
  from?: string;
  until?: string;
  userId?: string;
  resourceId?: string;
  resource?: AuditingResource[];
  action?: string;
  service: AuditingService;
  page?: number;
  size?: number;
  sortBy?: string;
  sortDesc?: boolean;
}
