/* eslint-disable */
export const messages = {
  system_admin_company_companies: "Companies",
  system_admin_company_login: "Sign In",
  system_admin_company_login_desc: "Sign In as Support User",
  system_admin_company_company: "Company",
  system_admin_company_company_groups: "Company Group",
  system_admin_company_company_group: "Company Group",
  system_admin_company_create_company_group: "Create Company Group",
  system_admin_company_create_company: "Create Company",
  system_admin_company_add_company: "Add Company",
  system_admin_company_delete_company: "Delete Company",
  system_admin_company_holdings: "Holdings",
  system_admin_company_holding: "Holding",
  system_admin_company_add_holding: "Add Holding",
  system_admin_company_edit_holding: "Edit Holding",
  system_admin_company_delete_holding_confirmation: "Do you really want to delete the holding?",
  system_admin_company_delete_company_confirmation: "Do you really want to delete the company from the company group?<br> On deletion of a company from a company group all related holdings will also be deleted.",
  system_admin_company_parent: "Parent",
  system_admin_company_subsidiary: "Subsidiary",
  system_admin_company_logo_description: "The logo should be a PNG file. The preferred resolution is 360*120 Pixel",
  system_admin_company_company_name: "Company Name",
  system_admin_company_company_logo: "Company Logo",
  system_admin_company_company_successfully_created: "Company successfully created",
  system_admin_company_company_details: "Company Details",
  system_admin_company_users: "Users",
  system_admin_company_user: "User",
  system_admin_company_bookings: "Bookings",
  system_admin_company_supplier: "Suppliers",
  system_admin_company_transport_routes: "Transport routes",
  system_admin_company_active_co2_types: "Active CO<sub>2</sub> Types",
  system_admin_company_inactive_co2_types: "Inactive CO<sub>2</sub> Types",
  system_admin_company_upload_logo: "Upload Company Logo",
  system_admin_company_upload: "Upload",
  system_admin_company_update_logo_description: "If you select a new logo, the existing one will be replaced.",
  system_admin_company_logo_successfully_uploaded: "Logo successfully uploaded",
  system_admin_company_connected_partners: "Connected Partners",
  system_admin_company_pending_partners: "Pending Partners",
  system_admin_company_external_esg_ratings: "External ESG Ratings",
  system_admin_company_edit_company: "Edit Company",
  system_admin_company_successfully_edited: "Company successfully edited",
  system_admin_company_edit_user: "Edit User",
  system_admin_company_licenses: "Licenses",
  system_admin_company_add_license: "Add License",
  system_admin_company_edit_license: "Edit License",
  system_admin_company_delete_license: "Delete license",
  system_admin_company_delete_license_title: "Do you want to delete the license?",
  system_admin_company_delete_license_message: "It will be deleted permanently",
  system_admin_company_license_type: "License type",
  system_admin_company_valid_from: "Valid from",
  system_admin_company_valid_until: "Valid until",
  system_admin_company_percentage_validation: "Value must be between 0 and 100",
  system_admin_company_circular_validation: "Creating holding between Company and itself is not possible",
  system_admin_company_active: "Active",
  system_admin_company_ordered: "Ordered",
  system_admin_company_void: "Void",
  system_admin_company_open: "Open",
  system_admin_company_paid: "Paid",
  system_admin_company_download_invoice: "Download Invoice",
  system_admin_company_license_status: "License Status",
  system_admin_company_requested_at: "Order Date",
  system_admin_company_license_info: "License Info",
  system_admin_company_ordered_by: "Ordered By",
  system_admin_company_invoice_status: "Invoice Status",
  system_admin_company_no_Licenses: "No Licenses",
  system_admin_company_group_diagram: "Diagram",
};
