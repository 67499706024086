/* eslint-disable */
export const messages = {
  customer_welcome_news: "News",
  customer_welcome_news_title_14_11_2024: "Freemium Version of the CSRD Module",
  customer_welcome_news_content_14_11_2024: `GLOBAL CLIMATE is pleased to introduce the Freemium Version of the CSRD Module: <ul class="ma-1"> <li>Allow interested companies to get an overview of the core functionalities of the double materiality assessment</li> <li>Create IROs (impacts, risks and opportunities) </li> <li>Invite one stakeholder to understand the stakeholder process</li> </ul>If you are also faced with the challenge of meeting your CSRD requirements, please do not hesitate to contact us.`,
  customer_welcome_news_title_09_11_2024: "New features of the GLOBAL SUITE",
  customer_welcome_news_content_09_11_2024: `GLOBAL CLIMATE is pleased to present the following new features of the CCF Module: <ul class="ma-1"> <li>Extension of the versioning mechanism by introduction of finalised reports functionality</li> <li>New rounding mechanism in financial bookings</li> <li>Asynchronous calculation of emissions for significant performance improvements for our customers with large datasets</li> </ul>`,
  customer_welcome_benefit_when_using_sco2pes: "GLOBAL FOOTPRINT CO<sub>2</sub> Accounting",
  customer_welcome_sco2pes_benefit_1:
    "Process integrated and financial data based CO<sub>2</sub> accounting according to the GHG protocol",
  customer_welcome_sco2pes_benefit_2: "Use of real values from your company instead of estimates and statistics",
  customer_welcome_sco2pes_benefit_3: "Real-time results",
  customer_welcome_sco2pes_benefit_4: "Complete CO<sub>2</sub> balance (Scope 1, Scope 2 and Scope 3)",
  customer_welcome_sco2pes_benefit_5: "Scientifically verified CO<sub>2</sub> database",
  customer_welcome_sco2pes_benefit_6: "Customized accounting of CO<sub>2</sub> relevant business transactions",
  customer_welcome_sco2pes_benefit_7: "Interface to SAP, Microsoft Dynamics and DATEV",
  customer_welcome_dialog_title: "Welcome on the GLOBAL SUITE",
  customer_welcome_dialog_description:
    "You successfully registered on the GLOBAL SUITE.<br />Feel free to look around the platform. You can find your open surveys in the navigation menu under Surveys.",
  customer_freemium_dialog_title: "Hi there, great to have you here!",
  customer_freemium_dialog_description: "Discover our CSRD-Tool and get a first taste of the <br> GLOBAL SUITE.</br> <br /> <b>Here's how it works:</b> <ol style='justify-items: left'><li>Click on <b>GLOBAL COMPLIANCE</b> in the left navigation menu.</li><li>Select the <b>CSRD-Tool</b>.</li><li>Try out the three core features.</li></ol> <br/> Enjoy exploring the software! 😊<br/> <b>Any questions or feedback?</b> <br/> Feel free to reach out! <br/> <br/> Your license is valid until {0}",
  customer_freemium_dialog_button_demo: "Book a demo",
  customer_freemium_dialog_go_to_csrd: "Go to CSRD-Tool",
  customer_welcome_news_update: "New Update",
  customer_welcome_banner_title_1: "Welcome to the",
  customer_welcome_banner_title_2: "GLOBAL SUITE",
  customer_welcome_banner_title_3:
    "The all-in-one software solution for <b>efficient, compliant and audit-proof</b> sustainability reporting",
  customer_welcome_survey_title_1: "You have survey to complete",
  customer_welcome_survey_subtitle_plural: "You have <strong>{0}</strong> surveys open",
  customer_welcome_survey_subtitle_singular: "You have <strong>{0}</strong> survey open",
  customer_welcome_update_title: "The new update is released",
  customer_welcome_update_subtitle: "Improvements of the GLOBAL SUITE",
  customer_welcome_product_title: "Our Solution",
  customer_welcome_product_subtitle:
    "Our software fully complies with the regulatory ESG requirements of CSRD and LkSG and brings a maximum of efficiency in your CO<sub>2</sub>-Accounting and -Reporting.",
  customer_welcome_product_scopes_description:
    "Fully automated CO<sub>2</sub>-Accounting in Scope 1, 2, 3 and calculation of Product Carbon Footprints along the entire product life cycle.",
  customer_welcome_product_scores_description:
    "Fulfill statutory reporting obligations completely, legally compliant and audit-proof.",
  customer_welcome_ccf_product_link: "CCF-Tool",
  customer_welcome_pcf_product_link: "PCF-Tool",
  customer_welcome_sbt_product_link: "SBT-Tool",
  customer_welcome_esg_product_link: "CSRD-Tool",
  customer_welcome_lksg_product_link: "LkSG-Tool",
  customer_welcome_csrd_product_link: "ESG-Tool",
  customer_welcome_sales_title: "Contact sales team for more information",
  customer_welcome_sales_subtitle:
    "We look forward to hearing from you. Just contact us by phone, email or contact form or book a demo directly. We are ready! ",
  customer_welcome_sales_cta_1: "Contact sales",
  customer_welcome_sales_cta_2: "Book a demo now",
  customer_welcome_sales_dialog_title: "We look forward to hearing from you",
  customer_welcome_sales_dialog_description:
    "Feel free to give us a call or contact us via email. We are here and look forward to a first meeting to get to know you!",
  customer_welcome_sales_support_ticket: "Support ticket",
};
