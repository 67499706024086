import Vue from "vue";
import Vuex from "vuex";
import { Identity, IdentityCompany, Lang, Settings } from "@gc/models";
import { SelectedCompanyUrl } from "@gc/urls";

Vue.use(Vuex);

interface StateInterface {
  identity: Identity;
  company: (companyId: string) => IdentityCompany;
  currentCompany: () => IdentityCompany;
  settings: Settings;
  breadcrumbItems: any[];
  permaLinkToken?: string;
}

export default new Vuex.Store<StateInterface>({
  state: {
    identity: Identity.load(),
    // TODO Why should we use this? Why does it seem that some usages of this method can be replaced by currentCompany method?
    company: (companyId: string) => {
      const identity = Identity.load();
      return identity.getCompany(companyId);
    },
    currentCompany: () => {
      const identity = Identity.load();
      return identity.getCompany(SelectedCompanyUrl.extractId());
    },
    settings: Settings.load(),
    breadcrumbItems: [],
  },
  mutations: {
    setLang(state, lang: Lang) {
      state.settings.updateLang(lang);
    },
    setPermaLinkToken(state, permaLinkToken?: string) {
      state.permaLinkToken = permaLinkToken;
    },
    setUserName(state, context: { firstName: string; lastName: string }) {
      state.identity.updateName(context);
    },
    setIdentity(state, identity: Identity) {
      state.identity = identity;
      state.identity.save();
    },
    setBreadcrumb(state, items: any[]) {
      state.breadcrumbItems = items;
    },
  },
});
