/* eslint-disable */
export const messages = {
  system_admin_company_companies: "Unternehmen",
  system_admin_company_login: "Anmelden",
  system_admin_company_login_desc: "Melden Sie sich als Support-Benutzer an",
  system_admin_company_company: "Unternehmen",
  system_admin_company_company_groups: "Unternehmensgruppen",
  system_admin_company_company_group: "Unternehmensgruppe",
  system_admin_company_create_company_group: "Unternehmensgruppe erstellen",
  system_admin_company_create_company: "Unternehmen erstellen",
  system_admin_company_add_company: "Unternehmen hinzufügen",
  system_admin_company_delete_company: "Unternehmen löschen",
  system_admin_company_holdings: "Beteiligungen",
  system_admin_company_holding: "Beteiligung",
  system_admin_company_add_holding: "Beteiligung hinzufügen",
  system_admin_company_edit_holding: "Beteiligung bearbeiten",
  system_admin_company_delete_holding_confirmation: "Wollen Sie die Beteiligung wirklich löschen?",
  system_admin_company_delete_company_confirmation: "Wollen Sie das Unternehmen wirklich aus der Unternehmensgruppe löschen?<br> Bei der Löschung werden auch alle zugehörigen Beteiligungen gelöscht.",
  system_admin_company_parent: "Muttergesellschaft",
  system_admin_company_subsidiary: "Tochtergesellschaft",
  system_admin_company_logo_description: "Das Logo sollte eine PNG-Datei sein. Die bevorzugte Auflösung beträgt 360*120 Pixel",
  system_admin_company_company_name: "Name der Firma",
  system_admin_company_company_logo: "Firmenlogo",
  system_admin_company_company_successfully_created: "Unternehmen erfolgreich gegründet",
  system_admin_company_company_details: "Unternehmens-Details",
  system_admin_company_users: "Nutzer",
  system_admin_company_user: "Benutzer",
  system_admin_company_bookings: "Buchungen",
  system_admin_company_supplier: "Lieferanten",
  system_admin_company_transport_routes: "Transportwege",
  system_admin_company_active_co2_types: "Aktive CO<sub>2</sub>-Typen",
  system_admin_company_inactive_co2_types: "Inaktive CO<sub>2</sub>-Typen",
  system_admin_company_upload_logo: "Firmenlogo hochladen",
  system_admin_company_upload: "Hochladen",
  system_admin_company_update_logo_description: "Wenn Sie ein neues Logo auswählen, wird das vorhandene ersetzt.",
  system_admin_company_logo_successfully_uploaded: "Logo erfolgreich hochgeladen",
  system_admin_company_connected_partners: "Vernetzte Partner",
  system_admin_company_pending_partners: "Offene Partnereinladungen",
  system_admin_company_external_esg_ratings: "Externe ESG Ratings",
  system_admin_company_edit_company: "Unternehmen bearbeiten",
  system_admin_company_successfully_edited: "Unternehmen erfolgreich bearbeitet",
  system_admin_company_edit_user: "Benutzer bearbeiten",
  system_admin_company_licenses: "Lizenzen",
  system_admin_company_add_license: "Lizenz hinzufügen",
  system_admin_company_edit_license: "Lizenz bearbeiten",
  system_admin_company_delete_license: "Lizenz löschen",
  system_admin_company_delete_license_title: "Möchten Sie die Lizenz löschen?",
  system_admin_company_delete_license_message: "Sie wird endgültig gelöscht",
  system_admin_company_license_type: "Lizenztyp",
  system_admin_company_valid_from: "Gültig ab",
  system_admin_company_valid_until: "Gültig bis",
  system_admin_company_percentage_validation: "Wert muss zwischen 0 und 100 liegen",
  system_admin_company_circular_validation: "Eine Beteiligung am eigenen Unternehmen ist nicht möglich",
  system_admin_company_active: "Aktiv",
  system_admin_company_ordered: "Bestellt",
  system_admin_company_void: "Leere",
  system_admin_company_open: "Öffnen Sie",
  system_admin_company_paid: "Bezahlt",
  system_admin_company_download_invoice: "Rechnung herunterladen",
  system_admin_company_license_status: "Lizenz-Status",
  system_admin_company_requested_at: "Order Date",
  system_admin_company_license_info: "Lizenz-Informationen",
  system_admin_company_ordered_by: "Bestellt von",
  system_admin_company_invoice_status: "Status der Rechnung",
  system_admin_company_no_Licenses: "Keine Lizenzen",
  system_admin_company_group_diagram: "Diagramm",
};
